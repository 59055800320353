import React, { useState }  from "react";
import { motion } from "framer-motion"


import * as styles from "./selector.module.css";

const Selector = ( {active, clickHandler, children} ) => {

    const variants = {
        active: { opacity: 1},
        inactive: { opacity: 0},
    }
    
    return (
        <ul className={styles.root}>
            {children.map((child, i) => (
                <motion.li key={i} onClick={() => clickHandler(i)} animate={{ opacity: active == i ? 1 : 0.3 }}>{child}</motion.li>
            ))}
        </ul>
    );
};

export default Selector;
