// extracted by mini-css-extract-plugin
export var title1 = "button-module--title1--b592J";
export var title2 = "button-module--title2---B5j9";
export var title3 = "button-module--title3--yTVQ+";
export var large = "button-module--large--p0bIF";
export var base = "button-module--base--PulcT";
export var small = "button-module--small--meCkG";
export var micro = "button-module--micro--itzpZ";
export var paragraph = "button-module--paragraph--npXck";
export var blockQuote = "button-module--blockQuote--96tAn";
export var responsiveTitle1 = "button-module--responsiveTitle1--ZnkhI";
export var responsiveTitle2 = "button-module--responsiveTitle2--au3gV";
export var responsiveTitle3 = "button-module--responsiveTitle3--cLv6x";
export var responsiveTitle4 = "button-module--responsiveTitle4--T9XAS";
export var centered = "button-module--centered--qoOtO";
export var root = "button-module--root--SPZQ7";