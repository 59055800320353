import React from "react";

import Hero from "./hero"
import Columns from "./columns"
import Slider from "./slider"
import SectionHeading from "./section-heading"
import DynamicArticle from "./dynamic-article"
import ProjectSlider from "./project-slider";

function PageBuilder(props) {
  const { type, pageBuilder, _rawPageBuilder } = props;

  // Load the right component, based on the _type from Sanity
  const Components = {
    hero: Hero,
    sectionHeading: SectionHeading,
    dynamicArticle: DynamicArticle,
    columns: Columns,
    slider: Slider,
    projectSlider: ProjectSlider,
  };

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index],
      });
    }
  });
}

export default PageBuilder;