import React, { useState, useEffect }  from "react";
import { motion, AnimatePresence } from "framer-motion"
import { imageUrlFor } from "../lib/image-url";


import { cn } from "../lib/helpers";

import * as styles from "./dynamic-article.module.css";


const DynamicArticle = ({block, raw, index }) => {

  const {content} = block

  const [active, setActive] = useState(0);

  const variants = {
    active: { opacity: 1, y: 0, y: 0 },
    inactive: { opacity: 0, y: 120 },
  }

  const background = {
    visible: { opacity: 1},
    hidden: {opacity: 0}
  }

  return (
    <article className={styles.root}>

      <div className={styles.section}>
        <ul className={styles.selector}>
          {content.map((content, i) => (
            <motion.li 
              onClick={() => setActive(i)} 
              animate={{ opacity: active == i ? 1 : 0.3 }}
            >
              
              {content.heading}
            </motion.li>
          ))}
        </ul>
        <div>
          {content.map((content, i) => (
            <motion.div variants={variants} transition={{ duration: 0.3 }} initial={ active == i ? "active" : "inactive"} animate={ active == i ? "active" : "inactive"} className={i == 0 ? cn(styles.firstInSection,styles.text) :  styles.text}>
              <h3 className={styles.heading}>{content.heading}</h3>
              <p className={styles.excerpt}>{content.excerpt}</p>
            </motion.div>
        ))}
        </div>
      </div>

      <div className={styles.section}>
      {content.map((content, i) => (
        content.figure && content.figure.asset && (
          <motion.div className={i == 0 ? cn(styles.image,styles.firstInSection) : styles.image } variants={variants} transition={{ duration: 0.3 }} initial={ active == i ? "active" : "inactive"} animate={ active == i ? "active" : "inactive"}>
            <img className={styles.figure} src={imageUrlFor(content.figure).width(1728).dpr(1).url()} />
          </motion.div>
        )
        ))}
      </div>
      {content.map((content, i) => (
        content.figure && content.figure.asset && (
          <motion.div className={styles.background} variants={background} transition={{ duration: 0.3 }} initial={ active == i ? "visible" : "hidden"} animate={ active == i ? "visible" : "hidden"}>
            <img src={imageUrlFor(content.figure).width(1728).dpr(2).blur(1500).url()} />
          </motion.div>
        )
        ))}
    
    </article>
  );
};

export default DynamicArticle;
