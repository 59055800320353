import React from "react";

import { Link } from "gatsby";

import { useState } from "react";

import * as styles from "./button.module.css";

export function Button({children, icon, linkType, url, setVideo}) {

  if (linkType == 'email') {
    return (
      <button className={styles.root}>
        <a href={url}>{children}</a>
      </button>
    );
  } else {
    return (
      <button className={styles.root} onClick={() => setVideo(String(url))}>
        {children}
      </button>
    );
  }

}
