import { Link } from "gatsby";
import React from "react";
import { imageUrlFor } from "../lib/image-url";

import * as styles from "./slider.module.css";

const Slider = ({block, raw, index }) => {
  const {title, content} = block
  
  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {content.map(content => (
          <li className={styles.listItem}>
            {content.logo && content.logo.asset && (
              <img src={imageUrlFor(content.logo).width(1024).dpr(2).url()} />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Slider;
