// extracted by mini-css-extract-plugin
export var title1 = "columns-module--title1--Ah39C";
export var title2 = "columns-module--title2--E9a8F";
export var title3 = "columns-module--title3--EQuN9";
export var large = "columns-module--large--4rBvu";
export var base = "columns-module--base--XgU8H";
export var small = "columns-module--small--urOsp";
export var micro = "columns-module--micro--VA35i";
export var paragraph = "columns-module--paragraph--uBPiM";
export var blockQuote = "columns-module--blockQuote--u2yJK";
export var responsiveTitle1 = "columns-module--responsiveTitle1--BiX7o";
export var responsiveTitle2 = "columns-module--responsiveTitle2--LJpYJ";
export var responsiveTitle3 = "columns-module--responsiveTitle3--0OfZz";
export var responsiveTitle4 = "columns-module--responsiveTitle4--qUYqh";
export var centered = "columns-module--centered--1tXDI";
export var full = "columns-module--full--rz+J3";
export var constrained = "columns-module--constrained--YZguC";
export var root = "columns-module--root--RB0NQ columns-module--constrained--YZguC";
export var column = "columns-module--column--ALU1A";
export var title = "columns-module--title--SZww3";