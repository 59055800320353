// extracted by mini-css-extract-plugin
export var title1 = "dynamic-article-module--title1--iHwDS";
export var title2 = "dynamic-article-module--title2--y3PXp";
export var title3 = "dynamic-article-module--title3--SBzC7";
export var large = "dynamic-article-module--large--TzqXO";
export var base = "dynamic-article-module--base--MWdKh";
export var small = "dynamic-article-module--small--SmWV3";
export var micro = "dynamic-article-module--micro--Xj1Mm";
export var paragraph = "dynamic-article-module--paragraph--B-SW9";
export var blockQuote = "dynamic-article-module--blockQuote--PJ9aP";
export var responsiveTitle1 = "dynamic-article-module--responsiveTitle1--0YKkv";
export var responsiveTitle2 = "dynamic-article-module--responsiveTitle2--rJwwj";
export var responsiveTitle3 = "dynamic-article-module--responsiveTitle3--VGx4s";
export var responsiveTitle4 = "dynamic-article-module--responsiveTitle4--fVJt1";
export var centered = "dynamic-article-module--centered--X5nWx";
export var full = "dynamic-article-module--full--WOTr4";
export var constrained = "dynamic-article-module--constrained--VhLB-";
export var root = "dynamic-article-module--root--88oWA dynamic-article-module--full--WOTr4";
export var selector = "dynamic-article-module--selector--B7ngX";
export var section = "dynamic-article-module--section--OEbTN";
export var text = "dynamic-article-module--text--v8j4U";
export var heading = "dynamic-article-module--heading--hS+e4";
export var excerpt = "dynamic-article-module--excerpt--7qf0v";
export var image = "dynamic-article-module--image--3vhBL";
export var figure = "dynamic-article-module--figure--kJKVk";
export var background = "dynamic-article-module--background--Fz0a4";
export var firstInSection = "dynamic-article-module--firstInSection--Z7Bd4";