import { Link } from "gatsby";
import React from "react";
import { imageUrlFor } from "../lib/image-url";

import * as styles from "./project-slider.module.css";

const ProjectSlider = ({block, raw, index }) => {
  const {title, content} = block
  return (
    <div className={styles.root}>
      <div className={styles.slider}>
        {content.map(content => (
          <div className={styles.card}>
            {content.mainImage && content.mainImage.asset && (
              <img src={imageUrlFor(content.mainImage).width(300).height(500).dpr(2).url()} />
            )}
            <div className={styles.cardText}>
                <p className={styles.title}>{content.title}</p>
                <p className={styles.client}>{content.client}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectSlider;
