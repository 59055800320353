// extracted by mini-css-extract-plugin
export var title1 = "hero-module--title1--+IRoY";
export var title2 = "hero-module--title2--erklX";
export var title3 = "hero-module--title3--Azf41";
export var large = "hero-module--large--vrEGQ";
export var base = "hero-module--base--ihGFs";
export var small = "hero-module--small--90n4T";
export var micro = "hero-module--micro--netCm";
export var paragraph = "hero-module--paragraph--ffdLr";
export var blockQuote = "hero-module--blockQuote--jaU--";
export var responsiveTitle1 = "hero-module--responsiveTitle1--6VC70";
export var responsiveTitle2 = "hero-module--responsiveTitle2--814VX";
export var responsiveTitle3 = "hero-module--responsiveTitle3--tNpZH";
export var responsiveTitle4 = "hero-module--responsiveTitle4--nXvbp";
export var centered = "hero-module--centered--+5AqV";
export var full = "hero-module--full--X1frd";
export var constrained = "hero-module--constrained--VIjP-";
export var root = "hero-module--root--ArULh";
export var text = "hero-module--text--JpAk2";
export var content = "hero-module--content--u1cO9";
export var heading = "hero-module--heading--75A8h";
export var projectSelector = "hero-module--projectSelector--Mkn2Q";
export var projectTitles = "hero-module--projectTitles--6NXNc";
export var projectImages = "hero-module--projectImages--0WhKa";
export var projectImage = "hero-module--projectImage--BtfOi";
export var tagline = "hero-module--tagline--vc2kE";
export var button = "hero-module--button--HwtA+";