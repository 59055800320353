import React, { useState }  from "react";
import { imageUrlFor } from "../lib/image-url";
import { motion } from "framer-motion"

import { Button } from "./button";
import Selector from "./selector";


import * as styles from "./hero.module.css";
import { responsiveTitle1, responsiveTitle2 } from "../components/typography.module.css";



const Hero = ({block, raw, index }) => {
  
  const {content} = block
  const [active, setActive] = useState(0);


  const projectImages = content.map((content) => 
    imageUrlFor(content.mainImage).width(414).height(736).dpr(2).url()

  )

  return (
    <section className={styles.root}>
      <nav className={styles.projectSelector}>
        <ul className={styles.projectTitles}>
          {content.map((content, i) => (
            <motion.li
              onClick={() => setActive(i)}
              animate={{ opacity: active == i ? 1 : 0.3 }}
            >
              {content.title}
            </motion.li>
          ))}
        </ul>
      </nav>
      <motion.div 
        className={styles.projectImages}
        animate={{ x: active * -100 + "vw" }}
        transition={{ type: "tween", stiffness: 100 }}
      >
        {content.map((content) => 
          
          <picture className={styles.projectImage}>
            <source 
              srcSet={imageUrlFor(content.mainImage).width(375).height(667).dpr(2).url()} media="(max-width: 567px)"/>
            <source srcSet={imageUrlFor(content.mainImage).width(1000).dpr(2).url()} media="(min-width: 568px)"/>
            <img src={content.mainImage}/>
          </picture>
        )}
      </motion.div>
     

    </section>
  );
};

export default Hero;
