import React from "react";
import { imageUrlFor } from "../lib/image-url";
import * as styles from "./columns.module.css";

import { responsiveTitle1, responsiveTitle2, responsiveTitle3, paragraph } from "../components/typography.module.css";


const Columns = ({block, raw, index }) => {
  const {content} = block

  return (
    <div className={styles.root}>
      {content.map(content => (
      <article className={styles.column}>
        <h3 className={styles.title}>{content.title}</h3>
        <p className={paragraph}>{content.text}</p>
      </article>
      ))}
    </div>
  );
};

export default Columns;
