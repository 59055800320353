import React from "react";
import { Button } from "./button";
import * as styles from "./section-heading.module.css";
import VideoPlayerPopover from "./video-popover";
import { useState } from "react";

const SectionHeading = ({block, raw, index }) => {

  const [video, setVideo] = useState("");

  return (
    <div className={styles.root}>
        <h2 className={styles.heading}>
          {block.heading}</h2>
          <p className={styles.tagline}>{block.tagline}</p>
          <p className={styles.blurb}>{block.blurb}</p>
          {block.linkText && <Button linkType={block.linkType} url={block.url} setVideo={setVideo}> {block.linkText} </Button>}
        <VideoPlayerPopover video={video} setVideo={setVideo}/>
    </div>
  );
};

export default SectionHeading;
