// extracted by mini-css-extract-plugin
export var title1 = "section-heading-module--title1--66UZX";
export var title2 = "section-heading-module--title2--l-0+t";
export var title3 = "section-heading-module--title3--q2lor";
export var large = "section-heading-module--large--29qdY";
export var base = "section-heading-module--base--OxW92";
export var small = "section-heading-module--small--BMhVL";
export var micro = "section-heading-module--micro--5omMK";
export var paragraph = "section-heading-module--paragraph--D3fcq";
export var blockQuote = "section-heading-module--blockQuote--GUWpe";
export var responsiveTitle1 = "section-heading-module--responsiveTitle1--jL6vv";
export var responsiveTitle2 = "section-heading-module--responsiveTitle2--gi3OQ";
export var responsiveTitle3 = "section-heading-module--responsiveTitle3---ejAQ";
export var responsiveTitle4 = "section-heading-module--responsiveTitle4--i3yb6";
export var centered = "section-heading-module--centered--SVUhP";
export var full = "section-heading-module--full--Nd0hE";
export var constrained = "section-heading-module--constrained--eSidi";
export var root = "section-heading-module--root--UR5qz section-heading-module--constrained--eSidi";
export var heading = "section-heading-module--heading--8LbTQ";
export var tagline = "section-heading-module--tagline--ZLxyc";
export var blurb = "section-heading-module--blurb--noHc7";